import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';

@Component({})
export default class AttendancePrintComponent extends Vue {

    private objScreenText: ScreenText = new ScreenText();
    tabs: any[] = JSON.parse(JSON.stringify(APP_CONST.TAB_SELECTION));

    @Prop() programName!: string;
    @Prop() siteName!:string;
    @Prop() currentSelectedClass!:any;
    @Prop() teacherList!:any;
    @Prop() currentWeek!:any;
    @Prop() scholarAttedanceList!:any;
    @Prop() header!:boolean;
    @Prop() totalScholarCount!:number;
    @Prop() no_data_view!:boolean;
    

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    concateUserName(userData:any){
      return userData.firstName+' '+userData.lastName;
    }

}