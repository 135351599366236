























































































































import AttendancePrintComponent from './AttendancePrintComponent';
export default AttendancePrintComponent;
